import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { AmbientPlayer } from '../../AmbientPlayer';
import { CallToActionButton } from '../CallToActionButton';
import { t } from '../../../domain/services/configService';
import { getColors } from '../../../domain/utils/colorUtils';
import { ParagraphWrapper } from '../ParagraphWrapper';

const InlineTextInner = ({ data }) => {
  const title = data.inlineTextTitle?.value;
  const text = data.inlineTextParagraph?.value;
  const hasVideo = !!data.bgVideo?.entity?.guid;
  const hasImage = !hasVideo && !!data.bgImage?.entity?.mediaImage?.url;

  const backgroundImageEntity = imageResizerService.overrideImageFocalPoint(
    data.bgImage
  );
  const backgroundImage = backgroundImageEntity?.mediaImage?.url;

  const backgroundImageUrls = backgroundImage
    ? imageResizerService.getStyleUrlsByBreakpoint(backgroundImage, {
        position: backgroundImageEntity?.focalPoint
      })
    : null;

  const colors = getColors(data.backgroundColor);
  const backgroundColor = backgroundImage ? '#000' : colors.background;
  const fontColor = backgroundImage ? 'white' : colors.color;

  const opacity = data.mediaTint ? (100 - data.mediaTint) / 100 : 1;

  const backgroundImageStyles = hasImage
    ? css`
        background: url(${backgroundImageUrls.mobile}) no-repeat
          ${imageResizerService.getBackgroundFocalPoint(
            backgroundImageEntity?.focalPoint
          )};
        ${theme.mq.tablet} {
          background-image: url(${backgroundImageUrls.tablet});
        }
        ${theme.mq.small_desktop} {
          background-image: url(${backgroundImageUrls.small_desktop});
        }
        ${theme.mq.large_desktop} {
          background-image: url(${backgroundImageUrls.large_desktop});
        }
      `
    : null;

  const styles = {
    inlineContainer: css`
      background-color: ${backgroundColor};
      clear: both;
      width: 100%;
      position: relative;
      p a {
        border-bottom: 3px solid #fc0;
        color: ${fontColor};
        &:hover {
          background: #fc0;
          color: #000;
        }
      }
      strong {
        font-weight: ${theme.font.weight('xl')};
      }
    `,
    inlineWrapper: css`
      color: ${fontColor};
      position: ${hasVideo ? 'absolute' : 'relative'};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: grid;
      place-items: center;
      text-align: center;
      &:before {
        display: ${hasVideo ? 'none' : 'block'};
        background-color: ${backgroundColor};
        ${backgroundImageStyles}
        background-size: cover;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: ${opacity};
      }
    `,
    inlineContentBox: css`
      position: relative;
      padding: ${theme.spacing.parse('$xl 0')};
      text-align: center;
      padding: ${theme.spacing.parse('$xl5 0 $xl4 0')};
      max-width: 360px;
      ${theme.mq.tablet} {
        max-width: 870px;
      }
      ${theme.mq.small_desktop} {
        max-width: 1020px;
      }
      ${theme.mq.large_desktop} {
        max-width: 1260px;
      }
    `,
    inlineTitle: css`
      ${theme.font.family('boldWeb')};
      font-size: ${theme.font.size('xl')};
      letter-spacing: ${theme.letterSpacing('base')};
      position: relative;
      ${theme.mq.small_desktop} {
        font-size: ${theme.font.size('xl3')};
      }
    `,
    inlineText: css`
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('base')};
      letter-spacing: ${theme.letterSpacing('s')};
      padding-top: ${theme.spacing('xs')};
      padding-bottom: ${theme.spacing('base')};
      position: relative;
      ${theme.mq.small_desktop} {
        font-6e: ${theme.font.size('l')};
        padding-top: ${theme.spacing('s')};
        padding-bottom: ${theme.spacing('xl')};
      }
    `,
    natgeoRectangle: css`
      background-color: ${title && text ? '#fc0' : 'transparent'};
      margin: auto;
      margin-top: ${theme.spacing('base')};
      margin-bottom: ${theme.spacing('xs')};
      height: 5px;
      width: 70px;
      position: relative;
    `,
    video: css`
      opacity: ${opacity};
      height: 66vh;
      padding-top: 0;
      width: 100%;
      ${theme.mq.small_desktop} {
        display: block;
        height: 66vh;
        padding-top: 0;
      }
    `,
    videoInner: css`
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
      width: 100%;
      position: absolute;
      ${theme.mq.small_desktop} {
        position: static;
        height: 66vh;
      }
    `
  };

  const ctaData = data.link?.url?.path
    ? {
        ctaButtonLink: data.link,
        ctaButtonText: data.link.title || t('Read More')
      }
    : null;

  return (
    <div css={styles.inlineContainer}>
      {hasVideo && (
        <div css={styles.video}>
          <div css={styles.videoInner}>
            <AmbientPlayer size="cover" videoEntity={data.bgVideo.entity} />
          </div>
        </div>
      )}

      <div css={styles.inlineWrapper}>
        <div css={styles.inlineContentBox}>
          {title && (
            <div css={styles.inlineTitle}>
              <div // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          )}
          <div css={styles.natgeoRectangle} />
          {text && (
            <div
              css={styles.inlineText}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          {ctaData ? <CallToActionButton data={ctaData} /> : null}
        </div>
      </div>
    </div>
  );
};

InlineTextInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export const InlineText = ParagraphWrapper(InlineTextInner);
